.entity-summary-container {
  padding: 10px 5px;
  border-radius: 4px;
}

.reports-navigation {
  display: block;

  li {
    background-color: rgb(160, 107, 37);
  }
}

.report-summaries-buttons {
  display: flex;
  gap: 4px;
  flex-direction: column;

  @media (max-width: 1000px) { flex-direction: row; }
}
