.appcard-container {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: left;
  align-items: center;
  transition: 0.3s;

  &:hover { background-color: #e1dfdf99; }

  &.locked {
    filter: contrast(0.5) brightness(1.3);
    cursor: not-allowed;
    background-color: #f2f2f2;
    img {
      filter: grayscale(100%);
    }

    &:hover { background-color: #e1dfdf99; }

    .lock-icon {
      position: absolute;
      width: 20px;
      right: 5px;
      top: 5px;
      rotate: 20deg;
    }
  }

  .hide-text {
    display: none;
  }

  .card-text {
    flex-grow: 2;
    margin-left: 20px;

    .label {
      color: #2c2c2c;
      margin-bottom: 4px;
      font-weight: 500;
    }

    .subtitle {
      margin-bottom: 4px;
    }

    .grade-tag {
      display: inline-block;
      font-size: 0.8em;
      background-color: #f4fcff;
      color: #3f3e65;
      margin: 2px 5px 2px 0px;
      padding: 2px 8px;
      border-radius: 2px;
    }
  }
}
