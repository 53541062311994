.home-grid-container {
  max-width: 1400px;
  margin: 10px auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 25%;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "mainBanner asideTop"
    "mainCenter asideTop"
    "mainCenter asideBottom"
    "mainBottom asideBottom";
  grid-column-gap: 1em;
  grid-row-gap: 1em;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "mainBanner"
      "asideTop"
      "mainCenter"
      "mainBottom"
      "asideBottom";
  }

  & > section:not(.main-banner-wrapper) {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;

    & > h2 {
      font-size: 1.3em;
      font-weight: 600;
      color: inherit;
    }
  }

  .main-banner-wrapper { grid-area: mainBanner; }
  .main-center-wrapper { grid-area: mainCenter; }
  .main-bottom-wrapper { grid-area: mainBottom; }
  .aside-top-wrapper { grid-area: asideTop; }
  .aside-bottom-wrapper { grid-area: asideBottom; }

  .app-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
    & > * { flex: 1 0 auto; }
  }

  .homepage-carousel {
    $anim-dur: 0.75s;
    overflow: hidden;
    position: relative;

    &-slider {
      transition-duration: $anim-dur;
      display: flex;
      & > * { flex: 0 0 100%; }
    }

    &-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      display: flex;
      gap: 8px;
      justify-content: center;

      & > button {
        transition-duration: $anim-dur;
        width: 24px;
        height: 6px;
        background-color: white;
        border: 0px solid white;
        cursor: pointer;

        opacity: 0.3;
        &:hover { opacity: 0.5; }
        &.current {
          opacity: 1.0;
          width: 32px;
        }
      }
    }
  }
}
